export const data = [
	{
		id: 1,
		name: "Log Houses",
		folder: "portfolio",
		info: "",
		img: "Log houses/7.jpg",
		link:'/log-resume'
	},
	{
		id: 2,
		name: "Timber Frame Houses",
		folder: "portfolio",
		info: "",
		img: "Timber frame houses/7.jpg",
		buttons: 2,
		link:'/timber-resume'
	},
	{
		id: 2,
		name: "Duo Wall Thermal Log Houses",
		folder: "portfolio",
		info: "",
		img: "Duo wall thermal log houses/7.jpg",
		link:'/duo-wall-resume'
	}
];
