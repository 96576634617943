export const timberData = [
  { id: 1, name: 'Diana 144m2', amount_of_images: 7, sq: '144m2', title: 'Diana', floors: '1.5', terrace: 'yes', bathrooms: '2', dimensions: '9×8', bedrooms: '4', garage: 'no', style: 'classic' },
  { id: 2, name: 'Julia 133m2', amount_of_images: 8, sq: '133m2', title: 'Julia', floors: '1', terrace: 'yes', bathrooms: '2', dimensions: '15×12', bedrooms: '3', garage: 'no', style: 'modern' },
  { id: 3, name: 'Leona 124m2', amount_of_images: 8, sq: '124m2', title: 'Leona', floors: '2', terrace: 'yes', bathrooms: '1', dimensions: '11×8', bedrooms: '4', garage: 'no', style: 'classic' },
  { id: 4, name: 'Kelly 116m2', amount_of_images: 7, sq: '116m2', title: 'Kelly', floors: '1', terrace: 'no', bathrooms: '2', dimensions: '12×10', bedrooms: '3', garage: 'no', style: 'hi-tech' },
  { id: 5, name: 'Tina 108m2', amount_of_images: 8, sq: '108m2', title: 'Tina', floors: '1.5', terrace: 'yes', bathrooms: '2', dimensions: '11×7', bedrooms: '3', garage: 'no', style: 'classic' },
  { id: 6, name: 'Dolores 102m2', amount_of_images: 8, sq: '102m2', title: 'Dolores', floors: '1', terrace: 'no', bathrooms: '1', dimensions: '10×10', bedrooms: '2', garage: 'no', style: 'modern' },
  { id: 7, name: 'Hanna 96m2', amount_of_images: 8, sq: '96m2', title: 'Hanna', floors: '1', terrace: 'yes', bathrooms: '1', dimensions: '12×8', bedrooms: '3', garage: 'no', style: 'modern' },
  { id: 8, name: 'Scarlet 93m2', amount_of_images: 8, sq: '93m2', title: 'Scarlet', floors: '1', terrace: 'yes', bathrooms: '1', dimensions: '12×9', bedrooms: '2', garage: 'no', style: 'modern' },
  { id: 9, name: 'Linda 86m2', amount_of_images: 8, sq: '86m2', title: 'Linda', floors: '2', terrace: 'yes', bathrooms: '2', dimensions: '7×6', bedrooms: '3', garage: 'no', style: 'barnhaus' },
  { id: 10, name: 'Monica 85m2', amount_of_images: 8, sq: '86m2', title: 'Monica', floors: '1', terrace: 'yes', bathrooms: '1', dimensions: '11×9', bedrooms: '2', garage: 'no', style: 'classic' },
  { id: 11, name: 'Olivia 84m2', amount_of_images: 8, sq: '84m2', title: 'Olivia', floors: '1', terrace: 'yes', bathrooms: '1', dimensions: '11×7', bedrooms: '2', garage: 'no', style: 'barnhaus' },
  { id: 12, name: 'Naomi 78m2', amount_of_images: 8, sq: '78m2', title: 'Naomi', floors: '1', terrace: 'yes', bathrooms: '2', dimensions: '9×8', bedrooms: '2', garage: 'no', style: 'classic' },
  { id: 13, name: 'Irma 72m2', amount_of_images: 8, sq: '72m2', title: 'Irma', floors: '1.5', terrace: 'yes', bathrooms: '1', dimensions: '6×6', bedrooms: '1', garage: 'no', style: 'classic' },
  { id: 14, name: 'Frida 71m2', amount_of_images: 8, sq: '71m2', title: 'Frida', floors: '1', terrace: 'yes', bathrooms: '1', dimensions: '7×9', bedrooms: '2', garage: 'no', style: 'classic' },
  { id: 15, name: 'Viola 67m2', amount_of_images: 8, sq: '67m2', title: 'Viola', floors: '1', terrace: 'yes', bathrooms: '1', dimensions: '7×9', bedrooms: '2', garage: 'no', style: 'barnhaus' },
  { id: 16, name: 'Alice 60m2', amount_of_images: 8, sq: '60m2', title: 'Alice', floors: '1', terrace: 'no', bathrooms: '1', dimensions: '8×9', bedrooms: '1', garage: 'no', style: 'hi-tech' },
  { id: 17, name: 'Gloria 54m2', amount_of_images: 8, sq: '54m2', title: 'Gloria', floors: '1+attic', terrace: 'no', bathrooms: '1', dimensions: '6×6', bedrooms: '1', garage: 'no', style: 'classic' },
  { id: 18, name: 'Sandra 49m2', amount_of_images: 8, sq: '49m2', title: 'Sandra', floors: '1', terrace: 'yes', bathrooms: '1', dimensions: '7×7', bedrooms: '1', garage: 'no', style: 'barnhaus' },
  { id: 19, name: 'Agatha 44m2', amount_of_images: 8, sq: '44m2', title: 'Agatha', floors: '1', terrace: 'yes', bathrooms: '1', dimensions: '7×7', bedrooms: '1', garage: 'no', style: 'barnhaus' },
  { id: 20, name: 'Rebecca 42m2', amount_of_images: 9, sq: '42m2', title: 'Rebecca', floors: '1', terrace: 'yes', bathrooms: '1', dimensions: '6×6', bedrooms: '2', garage: 'no', style: 'modern' },
  { id: 21, name: 'Amanda 42m2', amount_of_images: 8, sq: '42m2', title: 'Amanda', floors: '1', terrace: 'no', bathrooms: '1', dimensions: '7×7', bedrooms: '2', garage: 'no', style: 'modern' },
  { id: 22, name: 'Emely 36m2', amount_of_images: 6, sq: '36m2', title: 'Emely', floors: '1', terrace: 'no', bathrooms: '1', dimensions: '6×6', bedrooms: '1', garage: 'no', style: 'modern' },
];
